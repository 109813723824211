import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import NoteTemplate from '../src/components/NoteTemplates/NoteTemplate';
import ErrorModal from '../src/components/NoteTemplates/ErrorModal';
import Button from '../src/components/Button/Button';

const NoteTemplates = ({
  noteTemplates: initialNoteTemplates,
  organizationId,
  canEdit,
  csrfToken
}) => {
  const [noteTemplates, setNoteTemplates] = useState(initialNoteTemplates);
  const [isAddingNewTemplate, setIsAddingNewTemplate] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleError = useCallback(() => {
    setShowErrorModal(true);
  }, [showErrorModal]);

  const handleCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
  }, [showErrorModal]);

  const handleAddNewTemplate = useCallback(() => {
    setIsAddingNewTemplate(true);
    setNoteTemplates(prevTemplates => [
      ...prevTemplates,
      {
        id: '',
        type: 'DropOffNoteTemplate',
        body: '',
        organizationId,
        createdAt: '',
        updatedAt: ''
      }
    ]);
  }, [organizationId]);

  const handleCancelAddingNewTemplate = useCallback(() => {
    setIsAddingNewTemplate(false);
    setNoteTemplates([]);
  }, []);

  const handleUpdateTemplate = useCallback(
    (orgId, templateId, newBody, type) => {
      let url = `/organizations/${orgId}/note_templates`;
      let method = 'POST';
      let isCreate = true;

      if (templateId) {
        url += `/${templateId}`;
        method = 'PATCH';
        isCreate = false;
      }

      fetch(url, {
        method,
        body: JSON.stringify({
          note_template: { body: newBody, type }
        }),
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-type': 'application/json; charset=UTF-8'
        }
      })
        .then(response => response.json())
        .then(data => {
          setNoteTemplates(prevTemplates =>
            prevTemplates.map(template =>
              template.id === '' && isCreate
                ? { ...template, id: data.note_template.id, body: newBody }
                : template.id === templateId
                ? { ...template, body: newBody }
                : template
            )
          );
          setIsAddingNewTemplate(false);
        })
        .catch(handleError);
    },
    [csrfToken, handleError]
  );

  const handleDeleteTemplate = useCallback(
    (orgId, templateId) => {
      if (!templateId) return;

      fetch(`/organizations/${orgId}/note_templates/${templateId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-type': 'application/json; charset=UTF-8'
        }
      })
        .then(() => {
          setNoteTemplates(prevTemplates =>
            prevTemplates.filter(template => template.id !== templateId)
          );
          setIsAddingNewTemplate(false);
        })
        .catch(handleError);
    },
    [csrfToken, handleError]
  );

  const renderNoteTemplate = useCallback(
    noteTemplate => (
      <NoteTemplate
        key={noteTemplate.id}
        id={noteTemplate.id}
        type={noteTemplate.type}
        body={noteTemplate.body}
        organizationId={noteTemplate.organizationId}
        isAddingNewTemplate={isAddingNewTemplate}
        handleUpdateTemplate={handleUpdateTemplate}
        handleDeleteTemplate={handleDeleteTemplate}
        handleCancelAddingNewTemplate={handleCancelAddingNewTemplate}
        canEdit={canEdit}
      />
    ),
    [isAddingNewTemplate, handleUpdateTemplate, handleDeleteTemplate, canEdit]
  );

  return (
    <form>
      <h2 className="mt-12 mb-8">Note Templates</h2>
      <div>
        <p className="mute-text text-md mb-8 mt-0 w-full lg:w-3/4 p-0">
          Create templates for notes to include with Drop-off orders in your
          organization. Users can still manually add notes to individual orders
          when needed.
        </p>
      </div>

      {noteTemplates.length > 0 ? (
        <div className="mt-12 pl-6" id="show-note-template">
          <span className="mt-0 uppercase text-muted text-xs font-bold tracking-wider">
            body
          </span>
          <hr className="mt-8 mb-6 w-[106%] ml-[-3%]" />
          {noteTemplates.map(renderNoteTemplate)}
          <hr className="mb-6 w-[106%] ml-[-3%]" />
        </div>
      ) : (
        <Button
          className="btn rounded-[4px] text-[#065FD4] bg-[#0072ed1a] border-[#0072ed1a] hover:bg-[#0072ed1a] hover:border-[#0072ed1a] hover:text-[#065FD4]"
          onClick={handleAddNewTemplate}
          disabled={!canEdit}
        >
          Add New Template
        </Button>
      )}
      <ErrorModal
        isOpen={showErrorModal}
        onClose={handleCloseErrorModal}
        onConfirm={handleCloseErrorModal}
      />
    </form>
  );
};

NoteTemplates.propTypes = {
  noteTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      organizationId: PropTypes.number.isRequired
    })
  ).isRequired,
  organizationId: PropTypes.number.isRequired,
  canEdit: PropTypes.bool.isRequired,
  csrfToken: PropTypes.string.isRequired
};

export default NoteTemplates;
