import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import Button from '../Button/Button';

const NoteTemplate = props => {
  const {
    id,
    body: templateBody,
    type: templateType,
    organizationId,
    isAddingNewTemplate,
    handleUpdateTemplate,
    handleDeleteTemplate,
    handleCancelAddingNewTemplate,
    canEdit
  } = props;

  const textAreaRef = useRef(null);

  const [isEditing, setIsEditing] = useState(isAddingNewTemplate);
  const [allowSave, setAllowSave] = useState(templateBody !== '');
  const [editedBody, setEditedBody] = useState(templateBody);
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal
  ] = useState(false);

  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setIsEditing(isAddingNewTemplate);
  }, [isAddingNewTemplate]);

  useEffect(() => {
    setEditedBody(templateBody);
    setAllowSave(templateBody !== '');
  }, [templateBody]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleTextChange = useCallback(e => {
    const newBody = e.target.value;
    setEditedBody(newBody);
    setAllowSave(newBody !== '');
  }, []);

  const handleSave = useCallback(() => {
    handleUpdateTemplate(organizationId, id, editedBody, templateType);
    setIsEditing(false);
  }, [organizationId, id, editedBody, templateType, handleUpdateTemplate]);

  const handleCancel = useCallback(() => {
    if (isAddingNewTemplate && id === '') {
      handleCancelAddingNewTemplate();
    } else {
      setEditedBody(templateBody);
      setIsEditing(false);
    }
  }, [templateBody]);

  const handleShowDeleteConfirmation = useCallback(() => {
    setShowDeleteConfirmationModal(true);
  }, []);

  const handleCloseDeleteConfirmation = useCallback(() => {
    setShowDeleteConfirmationModal(false);
  }, []);

  const handleDelete = useCallback(() => {
    handleCloseDeleteConfirmation();
    handleDeleteTemplate(organizationId, id);
  }, [organizationId, id, handleDeleteTemplate, handleCloseDeleteConfirmation]);

  const btnClassNames =
    'btn btn-secondary border-0 focus:ring-0 rounded-full focus:outline-none p-0 pt-[5px] h-[38px] w-[38px]';

  const editingTemplate = (
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full">
      <textarea
        ref={textAreaRef}
        className={`${
          !allowSave ? 'border border-2 border-red' : 'border border-gray-300'
        } w-full sm:w-10/12 min-h-32 rounded-md p-4 mb-4 sm:mb-0`}
        onChange={handleTextChange}
        defaultValue={templateBody}
      ></textarea>
      <div className="w-full sm:w-2/12 flex justify-end sm:justify-center space-x-4">
        <Button onClick={handleCancel} className={btnClassNames}>
          <span className="text-gray-500 inline-block">
            <Icon
              name="closeThin"
              fill="#737173"
              size={18}
              viewBox={'0 0 18 18'}
            />
          </span>
        </Button>
        <Button
          onClick={handleSave}
          className={btnClassNames}
          disabled={!allowSave}
        >
          <span className="text-gray-500 inline-block">
            <Icon
              name="checkThin"
              fill="#737173"
              size={18}
              viewBox={'0 0 18 18'}
            />
          </span>
        </Button>
      </div>
    </div>
  );

  const showingTemplate = (
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full">
      <div
        className="w-full sm:w-10/12 mb-4 sm:mb-0"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: templateBody.replace(/\n/g, '<br />')
        }}
      />
      <div className="w-full sm:w-2/12 flex justify-end sm:justify-center space-x-4">
        <Button
          onClick={handleShowDeleteConfirmation}
          className={btnClassNames}
        >
          <span className="text-gray-500 inline-block">
            <Icon
              name="trashThin"
              fill="#737173"
              size={18}
              viewBox={'0 0 18 18'}
            />
          </span>
        </Button>
        <Button onClick={handleEdit} className={btnClassNames}>
          <span className="text-gray-500 inline-block">
            <Icon
              name="pencilThin"
              fill="#737173"
              size={18}
              viewBox={'0 0 18 18'}
            />
          </span>
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <div>{isEditing && canEdit ? editingTemplate : showingTemplate}</div>
      <DeleteConfirmationModal
        isOpen={showDeleteConfirmationModal}
        onConfirm={handleDelete}
        onClose={handleCloseDeleteConfirmation}
        onCancel={handleCloseDeleteConfirmation}
      />
    </div>
  );
};

NoteTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
  isAddingNewTemplate: PropTypes.bool.isRequired,
  handleUpdateTemplate: PropTypes.func.isRequired,
  handleDeleteTemplate: PropTypes.func.isRequired,
  handleCancelAddingNewTemplate: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired
};

export default NoteTemplate;
