import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Modal from '../Modals/Modal';

const ErrorModal = ({ isOpen, onConfirm, onClose }) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <Modal id="claim-warning-modal" isOpen={isOpen} onClose={handleClose}>
      <>
        <h3 className="m-3 my-5">An error occurred while saving</h3>
        <div className="mx-3 my-12">
          Please try again. If the problem persists, please contact your
          organization admin.
        </div>
        <div className="flex flex-row space-x-4 m-3 my-5">
          <Button className={'btn btn-primary'} onClick={handleConfirm}>
            Accept
          </Button>
        </div>
      </>
    </Modal>
  );
};

ErrorModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};

export default ErrorModal;
