import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Modal from '../Modals/Modal';

const DeleteConfirmationModal = ({ isOpen, onConfirm, onClose, onCancel }) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <Modal
      id="claim-warning-modal"
      isOpen={isOpen}
      onClose={handleClose}
      boxCssClasses={'w-[30%] min-w-[300px]'}
    >
      <>
        <h3 className="m-3 my-5 text-lg">Delete Template?</h3>
        <div className="mx-3 my-12 text-md">
          Are you sure you want to delete this template? This action cannot be
          undone.
        </div>
        <div className="flex flex-row space-x-4 m-3 my-5 justify-end">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button className={'btn btn-primary'} onClick={handleConfirm}>
            Delete
          </Button>
        </div>
      </>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func
};

export default DeleteConfirmationModal;
