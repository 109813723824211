import React, { useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import BasicInfoPage from '../src/pages/CreateAccountForm/BasicInfoPage';
import PropTypes from 'prop-types';
import FlashMessages from '../src/components/Layout/FlashMessages';
import Button from '../src/components/Button/Button';
import Modal from '../src/components/Modals/Modal';

const UserCompleteSignUp = ({ user }) => {
  if (!user) {
    return null;
  }

  const terms = user.needed_terms;
  const email = user.email;
  const givenName = user.given_name;
  const familyName = user.family_name;
  const organizationName = user.organization?.name;
  const organizationAddress = user.organization?.address;
  const phoneNumber = user.phone_number;

  const [flashes, setFlashes] = useState([]);

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      email,
      given_name: givenName,
      family_name: familyName,
      organization_name: organizationName,
      address: organizationAddress,
      phone_number: phoneNumber
    }
  });

  const back = useCallback(() => {
    fetch('/users/sign_out').then(() => {
      window.location.href = '/';
    });
  });

  const onSubmit = data => {
    const token = document.querySelector('meta[name="csrf-token"]')?.content;
    const submitData = {
      terms: data.terms,
      user: {
        given_name: data.given_name,
        family_name: data.family_name,
        email: data.email,
        phone_number: data.phone_number
      }
    };

    fetch(`/users/${user.id}/complete_provisioned_signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify(submitData)
    }).then(async response => {
      if (response.ok) {
        const { redirect_url } = await response.json();
        if (redirect_url) {
          window.location.href = redirect_url;
        } else {
          window.location.href = '/';
        }
      } else {
        const { errors } = await response.json();
        if (errors) {
          setFlashes([]);

          const flashesToSet = [];
          Object.keys(errors).forEach(key => {
            flashesToSet.push({
              type: 'alert',
              message: `${errors[key]}`,
              id: new Date().getTime()
            });
          });
          setFlashes(flashesToSet);
        }
      }
    });
  };

  return (
    <Modal
      id="user-basic-info-prompt"
      overlayClearsModal={false}
      isOpen
      modalCssClasses="z-[1050]"
      boxCssClasses="p-8 overflow-auto"
    >
      <FlashMessages flashes={flashes} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <BasicInfoPage
            user={user}
            back={back}
            intentValues={[]}
            neededTerms={terms ?? []}
            inputConfig={{
              given_name: {
                disabled: givenName ? true : false
              },
              family_name: {
                disabled: familyName ? true : false
              },
              phone: {
                disabled: phoneNumber ? true : false
              },
              organization_name: {
                disabled: organizationName ? true : false
              },
              address: {
                disabled: organizationAddress ? true : false
              },
              terms: {
                hidden: !terms || terms.length === 0
              },
              intent: {
                hidden: true
              },
              referral: {
                hidden: true
              }
            }}
            button={
              <div className="flex justify-end">
                <Button
                  type="submit"
                  className="btn-lg"
                  style="primary"
                  size="md"
                >
                  Save and Continue
                </Button>
              </div>
            }
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

UserCompleteSignUp.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    phone_number: PropTypes.string,
    organization: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        description: PropTypes.string,
        geo_lat: PropTypes.string,
        geo_lng: PropTypes.string,
        google_place_id: PropTypes.string,
        iso_country_code: PropTypes.string,
        name: PropTypes.string,
        neighborhood: PropTypes.string,
        phone: PropTypes.string,
        postal_code: PropTypes.string,
        state: PropTypes.string,
        street: PropTypes.string
      })
    }),
    needed_terms: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, path: PropTypes.string })
    )
  })
};

export default UserCompleteSignUp;
